import React from "react"

import Layout from "components/Layout/en"
import Product from "components/Layout/Product/index"

import productImg from "../../use-cases/videos/rust.mp4"
import productIcon from "./MetalScanner.svg"
import { Table } from "react-bootstrap"
import { MdDone, MdCancel } from "react-icons/md"

import image1 from "./rust_1.jpg"
import image2 from "./metal_scanner_0.jpg"
import image3 from "../../use-cases/videos/bearing.mp4"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Automate the differentiation of surface defects on metal to less than a
        second! The MoonVision software automatically differentiates between
        different surfaces and defect types and passes this information on where
        you need it most.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automated quality inspection of metal surfaces</li>
        <li>Benefit from consistent quality and reduced lead times</li>
        <li>Detection and measurement with a camera & software</li>
        <li>Highest accuracy & comfort through artificial intelligence</li>
        <li>Visual Output or forwarding to subsequent systems</li>
      </ul>
    </React.Fragment>
  )

  return (
    <Layout location={props.location}>
      <Product
        id="MetalScanner"
        title="Quality inspection of metal parts in real time"
        subtitle="Automated visual inspection of metal parts"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated metal testing",
          "Consistently high quality",
          "Shortened lead time",
          "Find out now",
        ]}
        images={[image1, image2, image3]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">Surface inspection</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cracks</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Craters</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Imperfections</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Corrosion defects</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Crystallographic defects</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Constrictions within pipes</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Colour Stain</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>High-contrast defects (in general)</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  Roughness R<sub>a</sub> (µm)
                </td>
                <td>
                  <MdDone />
                  <sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>Roughness differentiation on a component</td>
                <td>
                  <MdDone />
                  <sup>2</sup>
                </td>
              </tr>
              <tr>
                <td>
                  Type of processing (z.B. Milling, Grinding, Planing, etc.)
                </td>
                <td>
                  <MdDone />
                  <sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>
                  Defects on components (e.g. blowhole), which are not visible
                </td>
                <td>
                  <MdCancel />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <small>
                    [1] 1 guaranteed to the extent that an expert can evaluate
                    the roughness or processing type with his own eyes.
                    <br />
                    [2] guaranteed as long as differences in contrast between
                    the surfaces are visible.
                  </small>
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Shape inspection</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>External dimensions</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Position of drillings</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Position von processed edges</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Contours</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            The MoonVision MetalScanner was specially developed for the surface
            inspection of metal parts. New technologies such as machine learning
            are used to detect and classify even the smallest deviations, which
            are difficult for employees to detect in a short period of time.
            Information gathered be used to determine how to proceed with the
            metal part, whether it can be used for production, or whether it
            needs to be replaced or reported, etc. Subsequently, measurements
            can also be carried out. Tests can ensure that the metallic parts
            meet applicable standards but you can also define your own test
            rules.
          </p>
          <p>
            The artificial intelligence used outshines existing approaches with
            regard to inspection accuracy - the evaluation process is fully
            automated. You profit from the time savings.
          </p>
          <p>
            The MoonVision MetalScanner supports you in the following process
            steps:
          </p>
          <ul>
            <li>Grain Structure Analysis</li>
            <li>Rolling and casting process</li>
            <li>
              Non-cutting forming processes such as bending and deep drawing
            </li>
            <li>Machining production techniques</li>
            <li>
              Surface treatment by painting, powder coating, electroplating and
              PVD
            </li>
            <li>Additive processes such as sintering</li>
          </ul>
          <p>
            The MoonVision MetalScanner can be used at different production
            steps from the incoming goods inspection to the outgoing goods
            inspection. Due to its self-sufficient design, the software can be
            integrated into a running production process or existing ERP
            systems.
          </p>
          <p>At the end of the process, results can also be:</p>
          <ul>
            <li>
              Displayed visually(on a monitor/alarms/storage of jpg files)
            </li>
            <li>Transmitted in tabular form</li>
            <li>Passed on to management information systems via APIs</li>
            <li>Used for process control via APIs</li>
          </ul>
          <p>Thanks to the MoonVision concept</p>
          <ul>
            <li>Individual adaptations can be carried out independently</li>
            <li>You stay in control of access and checkable parameters</li>
          </ul>
          <h3>Available where you need it</h3>
          <p>
            Available where you need it The MoonVision Metalscanner can use all
            existing camera systems as data sources - which meet the Genicam
            standard. We are also happy to advise you on the choice of the right
            system. The verification can be done in real time via our online
            platform. Alternatively, we can also install the system on site. In
            both cases you get access to the MoonVision Toolbox and can make
            adjustments e.g. for parameter adaptation and personalization at any
            time.
          </p>
          <h3>Simple to use</h3>
          <p>
            Due to the software design, the optimization and implementation of
            the MoonVision MetalScanner can be realized on site with little
            effort. Already after checking a few image/video data, you can be
            sure of the quality, further image data can also be added at a later
            point in time. All data is collected and stored centrally and can be
            retrieved by you at any time.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
